import router from "@/router/index.js";

export const shared = {
  methods: {
    forceRerender() {
      this.refresh += 1;
    },
    forceRef() {
      this.$router.go(0);
    },
    sendOff(where) {
      router.push(where);
    },
    bldSlides() {
      if (this.projects.length > 50) {
        alert("Selection too Large");
        exit;
      }
      if (this.nores == "No Results") {
        alert("No Selection");
        exit;
      }
      var slides = this.projects.map((data) => data.imglink);
      var url = store.durl;
      var formdata = {
        action: "bldslides",
        search: this.search,
        slides: slides,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          alert("Slides Created");
        })
        .catch((error) => alert(error));
    },
    sortBy(which) {
      if (which == "date") {
        this.projects.sort(function (a, b) {
          var dateA = new Date(a.date),
            dateB = new Date(b.date);
          return dateA - dateB;
        });
      }
      if (which == "chk") {
        this.projects.sort(function (a, b) {
          var dateA = new Date(a.chk),
            dateB = new Date(b.chk);
          return dateA - dateB;
        });
      }
      if (which == "title") {
        this.projects.sort((a, b) => (a.title < b.title ? -1 : 1));
      }
      if (which == "name") {
        this.projects.sort((a, b) => (a.name < b.name ? -1 : 1));
      }
      if (which == "camera") {
        this.projects.sort((a, b) => (a.camera < b.camera ? -1 : 1));
      }
      if (which == "lens") {
        this.projects.sort((a, b) => (a.lens < b.lens ? -1 : 1));
      }
      if (which == "focus") {
        this.projects.sort((a, b) => (a.focus < b.focus ? -1 : 1));
      }
      if (which == "fstop") {
        this.projects.sort((a, b) => (a.fstop < b.fstop ? -1 : 1));
      }
      if (which == "shutter") {
        this.projects.sort((a, b) => (a.shutter < b.shutter ? -1 : 1));
      }
      if (which == "iso") {
        this.projects.sort((a, b) => (a.iso < b.iso ? -1 : 1));
      }
      if (which == "count") {
        this.projects.sort((a, b) => b.count - a.count);
      }
      if (which == "numrows") {
        this.projects.sort((a, b) => b.numrows - a.numrows);
      }
      if (which == "reps") {
        this.projects.sort((a, b) => b.reps - a.reps);
      }
      if (which == "avg") {
        this.projects.sort((a, b) => b.avg - a.avg);
      }
      if (which == "rating") {
        this.projects.sort((a, b) => b.rating - a.rating);
      }
      if (which == "flash") {
        this.projects.sort((a, b) => (a.flash < b.flash ? -1 : 1));
      }
      if (which == "loc") {
        this.projects.sort((a, b) => (a.loc < b.loc ? -1 : 1));
      }
      if (which == "distance") {
        this.projects.sort((a, b) => a.distance - b.distance);
      }
      if (which == "visits") {
        this.projects.sort((a, b) => b.visits - a.visits);
      }
    },
    sortByD(which) {
      if (which == "date") {
        this.projects.sort(function (a, b) {
          var dateA = new Date(a.date),
            dateB = new Date(b.date);
          return dateB - dateA;
        });
      }
      if (which == "chk") {
        this.projects.sort(function (a, b) {
          var dateA = new Date(a.chk),
            dateB = new Date(b.chk);
          return dateB - dateA;
        });
      }
      if (which == "title") {
        this.projects.sort((a, b) => (a.title > b.title ? -1 : 1));
      }
      if (which == "name") {
        this.projects.sort((a, b) => (a.name > b.name ? -1 : 1));
      }
      if (which == "camera") {
        this.projects.sort((a, b) => (a.camera > b.camera ? -1 : 1));
      }
      if (which == "lens") {
        this.projects.sort((a, b) => (a.lens > b.lens ? -1 : 1));
      }
      if (which == "focus") {
        this.projects.sort((a, b) => (a.focus > b.focus ? -1 : 1));
      }
      if (which == "fstop") {
        this.projects.sort((a, b) => (a.fstop > b.fstop ? -1 : 1));
      }
      if (which == "shutter") {
        this.projects.sort((a, b) => (a.shutter > b.shutter ? -1 : 1));
      }
      if (which == "iso") {
        this.projects.sort((a, b) => (a.iso > b.iso ? -1 : 1));
      }
      if (which == "count") {
        this.projects.sort((a, b) => a.count - b.count);
      }
      if (which == "numrows") {
        this.projects.sort((a, b) => a.numrows - b.numrows);
      }
      if (which == "reps") {
        this.projects.sort((a, b) => a.reps - b.reps);
      }
      if (which == "avg") {
        this.projects.sort((a, b) => a.avg - b.avg);
      }
      if (which == "rating") {
        this.projects.sort((a, b) => a.rating - b.rating);
      }
      if (which == "flash") {
        this.projects.sort((a, b) => (a.flash > b.flash ? -1 : 1));
      }
      if (which == "loc") {
        this.projects.sort((a, b) => (a.loc > b.loc ? -1 : 1));
      }
      if (which == "distance") {
        this.projects.sort((a, b) => b.distance - a.distance);
      }
      if (which == "visits") {
        this.projects.sort((a, b) => a.visits - b.visits);
      }
    },
    getTasks() {
      var url = store.durl;
      var formdata = {
        action: "disptasks",
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.tasks = data;
        })
        .catch((error) => alert(error));
    },
    getPoints() {
      var url = store.durl;
      var formdata = {
        action: "disppoints",
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.points = data;
        })
        .catch((error) => alert(error));
    },
  },
};
